import { EnvConfig } from './env-config';

export const environment: EnvConfig = {
  env: 'uat',
  scopeApiUrl: 'https://coreapi.uat.scope.expert/',
  baseUrl: 'https://uat.scope.expert',
  baseJsUrl: '',
  production: true,
  baseHost: 'uat.scope.expert'
};
